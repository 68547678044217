@font-face {
    font-family: "fancy";
    src: url("./fonts/fancy.ttf");
}

::-webkit-scrollbar {
    width: 0;
}

::-moz-selection {
    /* Code for Firefox */
    color: #272727;
    background: #a87b52;
}

::selection {
    color: #272727;
    background: #a87b52;
}

* {
    padding: 0;
    margin: 0;
    font-family: 'Varela Round', sans-serif !important;
}

.css-10brbg3-control,
.css-10brbg3-control:hover {
    min-height: 44px !important;
}

.deleteQrButton:disabled,
button:disabled {
    background-color: #63461457;
    cursor: no-drop;
}

body {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: #f4f3ef;
}

#root {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: #8f8f8f; */
}

#root>div {
    /* overflow-x: hidden; */
}

html:has(#login-container) {
    background-color: #000;
}

#root:has(#login-container) {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./images/bg_3.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

#login-container>img {
    width: 38%;
}

#page-wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

#page-wrapper>div {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 150px;
    padding-bottom: 40px;
}


header {
    width: 150px;
    height: 100%;
    background-color: #634614;
    position: fixed;
    display: flex;
    min-width: 150px;
    left: 0;
    flex-direction: column;
    align-items: center;
    top: 0;
    z-index: 9999;
}

.logout-button {
    position: absolute;
    bottom: 0;
    margin-bottom: 3vw;
}

.header-boxes {
    cursor: pointer;
    transition: .2s;
}

.header-boxes p {
    pointer-events: none;
    transition: .2s;
    margin-right: 11px;
    margin-top: 2px;
}

.header-boxes>svg {
    display: block;
    fill: #ffffff;
    height: 22px;
    width: 22px;
    cursor: pointer;
    transition: .2s;
}


#data-range {
    width: 85vw;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-around;
    gap: 15px;
}

#data-range>div:nth-child(2) {
    opacity: 1;
}

#data-range>* {
    width: 25%;
    min-width: 250px;
}

.selection-container {
    width: 80vw;
    border-radius: 12px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    justify-content: space-around;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.label--selector {
    margin-top: 25px;
    width: 250px;
    min-width: 230px;
    padding: 5px 10px;
}

.label--selector #loading {
    background-color: rgb(222, 222, 222);
    border-radius: 5px;
    background: linear-gradient(to right,
            #e6e6e6,
            #dbdbdb,
            #e6e6e6);
    background-size: 200% 100%;
    animation: loading 3s ease-in-out infinite;
    border: 1px transparent solid;
}

@keyframes loading {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: -400% 0;
    }
}

.label--selector #loading * {
    opacity: 0;
}

.css-3w2yfm-ValueContainer>div {
    min-width: fit-content;
}

.selection-container>.label--selector>div {
    width: 100%;
}

#data-container {
    width: 80vw;
    height: fit-content;
    display: flex;
    justify-content: space-around;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

#data-container>div>svg {
    /* stroke-dasharray: 50;
    stroke-dashoffset: 43;
    stroke-linecap: round;
    transform: rotateZ(112deg);
    stroke-width: 1px;
    fill: transparent;
    /* animation: stroke 1s linear forwards; */
    /* transition: .1s; */
}

#data-box-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    width: 100%;
    gap: 15px;
    margin-bottom: 25px;
    flex-wrap: wrap;
}

.admin-labels-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 15px;
}

.label--selector .data-box div {
    text-align: end;
}

.data-box>svg {
    fill: #a87b52;
}

.data-box div p:nth-child(1) {
    color: #9a9a9a;
    font-size: 15px;
}

.data-box div p:nth-child(2) {
    color: #252422;
    font-size: 34px;
}

#data-container p {
    font-family: math;
}

/* .react-datepicker-popper{
    transition: .5s;
    transform: translate3d(0, 0, 0px) !important;
} */

.data-box {
    width: 22%;
    min-width: 230px;
    padding: 5px 10px;
    border-radius: 12px;
    display: flex;
    height: auto;
    justify-content: space-between;
    align-items: center;
    aspect-ratio: 2;
    background-color: #fff;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, .15);
    position: relative;
}

.data-box:nth-child(1),
.data-box:nth-child(2) {}

.data-box:nth-child(3),
.data-box:nth-child(4) {}

@keyframes stroke {
    0% {
        stroke-dashoffset: 189;

    }

    100% {
        stroke-dashoffset: 0;
    }
}



.brand-logo {
    height: auto;
    margin-top: 30px;
}

.brand-logo>img {
    width: 100%;
    height: 100%;
}

/* ============ LOGIN.JS ===================================================================================================================================*/
#login-container {
    max-width: 340px;
    max-height: 545px;
    width: 100%;
    height: fit-content;
    padding: 25px 0;
    gap: 15px;
    background-color: #f7e7cd;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    animation: box-shadow 1s infinite;
    position: relative;
}

#login-error {
    width: 85%;
    background-color: rgba(241, 17, 9, 0.938);
    color: #fff;
    padding: 5px;
    border-radius: 7px;
}

#login-title-para h1 {
    font-size: 16px;
    text-align: center;
    color: #644614;
}

#login-title-para h1:nth-child(1) {
    font-size: 13px !important;
}

@keyframes box-shadow {
    0% {
        box-shadow: rgba(0, 0, 0, 0.15) -10.95px 10.95px 2.6px;
    }

    50% {
        box-shadow: rgba(0, 0, 0, 0.15) -10.95px 15.95px 10.6px;
    }

    100% {
        box-shadow: rgba(0, 0, 0, 0.15) -10.95px 10.95px 2.6px;
    }
}






/* tech */
#tech-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 43px;
    gap: 50%;
    background-color: #634614;
}

#tech-header::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #634614;
    width: calc(100% + 30px);
    height: calc(100% + 30px);
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    transform: translate(-50%, -50%);
    z-index: -1;
}

header .brand-logo:hover {
    cursor: pointer;
}

#tech-header .brand-logo {
    height: 100%;
    width: auto;
    margin-top: 0;
}

#tech-header .brand-logo img {
    height: 90%;
    width: unset;
    margin-top: 5%;
}

#tech-header .header-boxes {
    border: none;
}

#tech-header .header-boxes svg {
    color: #b78021;
}

#tech-page-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    max-width: 500px;
    gap: 43px;
    place-self: center;
    background-color: #fff;
    padding: 15px;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

#tech-page-wrapper>section {
    width: 100%;
}

#tech-page-wrapper>section>div {
    height: 25vw !important;
    padding: 0 !important;
}

.tech-selector {
    width: 250px;
}

.leaflet-container {
    height: 250px !important;
    width: 100% !important;
    z-index: 0;
}

/* POPUP BACKGORUNDS */

.leaflet-popup-content-wrapper {
    background-color: #a87b52;
}

.leaflet-popup-tip {
    background-color: #a87b52;
}

div.leaflet-pane.leaflet-popup-pane>div>a>span {
    color: #ffffff;
    font: 29px/24px Tahoma, Verdana, sans-serif;
}

/* ----------------- */
.leaflet-popup-content {
    width: 100%;
    max-width: 144px;
    color: #ffffff;
}

#job-selector {
    width: 100%;
}

#tech-page-wrapper>#addLocation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    width: 100%;
}

#tech-page-wrapper>#addDevices {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    width: 100%;
}

#tech-page-wrapper>#addStocks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    width: 100%;
}

.switch--label {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
    gap: 10px;
    color: #7e7e7e;
}

#tech-page-wrapper>#addCoffeeSet {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    width: 100%;
}

#tech-page-wrapper>#addCoffeeSet>section {
    width: 100% !important;
    display: flex;
    justify-content: center;
    padding: 0;
}

#tech-page-wrapper>#addCoffeeSet>section video {
    width: 100% !important;
    height: auto !important;
}

#breath-svg {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 60%;
    left: 50%;
    z-index: 999;
    opacity: 0;
}

#tech-page-wrapper>#addCoffeeSet>section>div {
    width: 426px !important;
    height: 293px !important;
    padding: 0 !important;
    border: 3px #a87b52 solid;
    border-radius: 5px;
}



/* span[class*="Switch"] {
} */

.css-xjimrv-Switch {
    height: 28px !important;
    width: 47px !important;
}

.css-xjimrv-Switch>* {
    border-radius: 20px !important;
}

.label--input {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #7e7e7e;
    font-size: 16px;
    gap: 5px;
}

.label--input>input {
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    color: #7e7e7e;
    line-height: normal;
    height: auto;
    font-size: 14px;
    padding: 10px 15px;
    transition: color .3s ease-in-out, border-color .3s ease-in-out, background-color .3s ease-in-out;
    box-shadow: none;
    box-sizing: border-box;
}



.label--input>input:focus {
    border: 1px solid #644614;
    box-shadow: none;
    outline: 0 !important;
    color: #644614;
}

.sendDataButton {
    background-color: #634614;
    padding: 10px 35px;
    border-radius: 5px;
    place-self: flex-end;
    cursor: pointer;
    transition: .2s;
    color: #fff !important;
    font-size: 15px;
}





#chartWrapperWrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-around;
}

#chartWrapper {
    width: calc(299px + 22%);
    min-width: 300px;
}

canvas {
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, .15);
}

.react-datepicker__input-container {
    width: 99%;
}

#label--databox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
}


/* -------------------------- */
.tooltip {
    display: inline-block;
    position: absolute;
    text-align: left;
    cursor: pointer;
    top: 28%;
    right: 25%;
}

.tooltip>svg {
    width: 30px;
    fill: gray;
}

.tooltip h3 {
    margin: 12px 0;
}

.tooltip .right {
    min-width: max-content;
    max-width: 400px;
    top: 140%;
    left: -207%;
    margin-left: 20px;
    transform: translate(-50%, -50%);
    padding: 0 25px;
    color: #EEEEEE;
    background-color: #644614;
    font-weight: normal;
    font-size: 13px;
    border-radius: 8px;
    position: absolute;
    z-index: 99999999;
    box-sizing: border-box;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.8s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tooltip:hover .right {
    visibility: visible;
    opacity: 1;
}

.tooltip .right img {
    width: 400px;
    border-radius: 8px 8px 0 0;
}

.tooltip .text-content {
    padding: 10px 20px;
}

.tooltip .right i {
    position: absolute;
    top: 16%;
    right: 30%;
    margin-top: -12px;
    width: 12px;
    height: 24px;
    overflow: hidden;
    transform: translate(-50%, -50%) rotate(90deg);
}

.tooltip .right i::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: 0;
    top: 50%;
    transform: translate(50%, -50%) rotate(45deg);
    background-color: #644614;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}


/* *************************** */

#datePickerWrapper {
    height: max-content;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 25px;
    padding: 10px;
    margin: 15px 0 15px 0;
}

#datePickerWrapper:nth-child(2) {
    justify-content: flex-end;
}

#datePickerWrapper>div {
    width: 100%;
    min-width: 171.5px;
    position: relative;
    transition: .2s;
}

#datePickerWrapper>div:hover {
    transition: .2s;
}

#datePickerWrapper>div #bar {
    width: 90px;
}

#datePickerWrapper>div .datePicker {
    background-color: transparent;
    border: 2px #a87b52 solid;
    border-radius: 5px;
    transition: .3s;
    color: #a87b52;
}

.datePicker {
    width: 100%;
    padding: 13px 24px;
    background-color: transparent;
    box-sizing: border-box;
    border: 2px #b78021 solid;
    color: transparent;
    border-radius: 5px;
    transition: .3s;
    text-align: center;
}


#datePickerWrapper>div label {
    top: 0%;
    left: 0%;
    transform: translate(28%, -50%);
    color: #a87b52;
}

.react-datepicker-popper {
    z-index: 999 !important;
}

.react-datepicker__header {
    background-color: #644614 !important;
}

.react-datepicker__header * {
    color: #ccc !important;
}

.react-datepicker__month-container {
    background-color: #644714a1 !important;
}

.react-datepicker__month * {
    color: #ffffff !important;
}

.react-datepicker__month div:not(div[class*="selected"], div[class="react-datepicker__week"]):hover {
    background-color: #6447143d;
}

.react-datepicker__month div[class*="selected"] {
    background-color: #644614;
}

#datePickerWrapper>div>label {
    font-size: 13px;
    min-width: 59px;
    position: absolute;
    color: #b78021;
    padding: 0 15px;
    background: transparent;
    top: 0%;
    left: 0%;
    transform: translate(28%, -50%);
    color: #a87b52;
    z-index: 99;
    transition: .3s;
}


#bar {
    position: absolute;
    height: 4px;
    width: 0;
    background: #f4f3ef;
    top: -2px;
    left: 23px;
    z-index: 9;
    transition: .2s;
}



.react-datepicker-wrapper {
    width: 100% !important;
}

/* SELECTOR INPUTS STYLES */
.css-b62m3t-container * {
    box-shadow: none !important;
}

.css-b62m3t-container {}


div[class*="control"]:hover {
    border-color: #a87b52 !important;
}

div[class*="control"] {
    border-color: #a87b52 !important;
}


.css-1nmdiq5-menu div>div:hover {
    background: #a87b529e;
}

.css-1nmdiq5-menu * {
    color: black !important;
}

.css-1nmdiq5-menu {
    border: 1px #a87b52 solid !important;
    z-index: 99999 !important;
}


.css-1nmdiq5-menu div>div:hover .css-1evxvmh-control {
    box-shadow: 2px 2px #b78021;
}

.css-1nmdiq5-menu div>div {
    background-color: transparent;
}

.css-1p3m7a8-multiValue {
    background-color: #d8c4a17c !important;
    border: 1px #b78021 dashed !important;
}


.input--label {
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
}

.input--label>input:hover {}

input:focus+label,
input:-webkit-autofill+label {
    font-size: 14px;
    top: 0;
    left: 20%;
    transform: translate(0%, -50%) !important;
    transition: .3s;
}


input:not(:placeholder-shown)+label {
    font-size: 14px;
    top: 0;
    left: 20%;
    transition: .3s;
    transform: translate(0%, -50%) !important;
}

input:focus+label::before {
    padding: 0 15px;
    /* give same value */
    transform: translate(-15px
            /* give same value */
            , 0%);
    transition: .6s;
    transition-delay: .15s;
}

input:not(:placeholder-shown)+label::before {
    padding: 0 15px;
    /* give same value */
    transform: translate(-15px
            /* give same value */
            , -50%);
    transition: .6s;
    transition-delay: .15s;
}

.input--label * {
    -webkit-user-select: none;
    -webkit-user-drag: none;
}

.input--label>input:focus::-webkit-input-placeholder {
    color: #f0b997;
    text-align: center;
}

.input--label>input::-webkit-input-placeholder {
    color: transparent;
}

.input--label>label::before {
    content: "";
    width: 100%;
    transform: translate(0%, 0%);
    top: 50%;
    height: 10px !important;
    position: absolute;
    z-index: -1;
    background-color: #f7e7cd;
    transition: .5s;
}

.input--label>label {
    font-size: 14px;
    color: #a87b52;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    transition: .3s;
    pointer-events: none;
}


input[class="email"],
input[class="password"] {
    padding: 10px 20px;
    position: relative;
    border: 2px #a87b52 solid;
    background-color: transparent;
    outline: none;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    caret-color: #a87b52;
    color: #2c2c2c;
    font-size: 14px;
    cursor: text;
}

#passwordToggle {
    position: absolute;
    top: 50%;
    right: 15px;
    width: 21px;
    transform: translate(0, -50%);
    height: auto;
    cursor: pointer;
}

input[class="email"]:-webkit-autofill,
input[class="email"]:-webkit-autofill:hover,
input[class="email"]:-webkit-autofill:focus,
input[class="email"]:-webkit-autofill:active {
    transition: background-color 86400s ease-in-out 0s;
}

/* ---------- */
#button-wrapper {
    width: 85%;
    display: flex;
    justify-content: flex-end;
}

#sign-in {
    background-color: #634614;
    color: #fff;
    height: 40px;
    border-radius: 5px;
    width: 100%;
    transition: .2s;
    cursor: pointer;
    font-size: 16px;
    border: 1.5px #634614 solid;
    outline: none;

}

.padding {
    background-color: transparent !important;
    color: #634614 !important;
}

.padding p {
    color: #634614 !important;
}



/* -------------------------------------------------------------------------- */
#remember--signup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    width: 85%;
}

#remember--signup #seperator {
    height: 25px;
    width: 1px;
    background-color: #644614;
}

#sign-up {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    cursor: pointer;
}

#sign-up p {
    width: max-content;
}

#sign-up>* {
    height: 100%;
    -webkit-user-drag: none;
    -webkit-user-select: none;

}

#rememberMe {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: max-content;
    gap: 5px;
}

#rememberMe p {
    font-size: 12px;
}

#rememberMe>.custom-checkbox {
    width: 16px;
    aspect-ratio: 1;
    opacity: 1;
    position: relative;
}



#rememberMe>.custom-checkbox>input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

input[type="checkbox"]:checked+span {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #a87b52 !important;
    border: 1px #a87b52 solid !important;
    pointer-events: none;
}

input[type="checkbox"]:checked+span+svg {
    opacity: 1 !important;
    transition: .2s;
}



#forgot-password-div {
    width: max-content;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

#forgot-password-div>svg {
    width: 24px;
    height: 24px;
    fill: #a87b52;
}

#forgot-password-div p {
    cursor: pointer;
    transition: .2s;
    font-size: 12px;
}

#forgot-password-div p:hover {
    color: #a87b52;
    transition: .2s;
}

#rememberMe>.custom-checkbox svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: 0;
    transition: .2s;
    pointer-events: none;
    fill: rgb(250, 250, 250);
}


#rememberMe>.custom-checkbox>span {
    content: "";
    width: 100%;
    height: 100%;
    border: 1px #aaa7a4 solid;
    border-radius: 6px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #aaa7a4;
    pointer-events: none;
}


/* ELEGANT MODAL ERROR */

#myModal {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -99999;
    opacity: 0;
    transition: opacity .5s, top .5s !important;
}

#myModal.show {
    top: 50%;
    left: 50%;
    opacity: 1;
    transform: translate(-50%, -50%);
    z-index: 99999;
    transition: opacity .5s, top .5s !important;

}

.btn.btn-success {
    text-transform: uppercase;
    font-size: 16px;
}

.modal-confirm {
    color: #5c6270;
    max-width: 400px;
    width: 100%;
    min-width: 350px;
}

.modal-confirm .modal-content {
    padding: 20px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    background: #f3f3f3;
}

.modal-confirm p {
    text-align: center;
}

.modal-confirm .modal-header {
    background: #e85e6c;
    border-bottom: none;
    position: relative;
    text-align: center;
    margin: -20px -20px 0;
    border-radius: 5px 5px 0 0;
    padding: 15px;
}

#myModal.success .modal-header {
    background: #47c9a2;

}

.modal-confirm .modal-header svg {
    width: 70px;
    height: 70px;
    fill: #fff;
}

.modal-confirm h4 {
    text-align: center;
    font-size: 36px;
    margin: 10px 0;
}

.modal-confirm .form-control,
.modal-confirm .btn {
    min-height: 40px;
    border-radius: 3px;
}

.modal-confirm .close {
    position: absolute;
    top: 2px;
    right: 10px;
    color: #fff;
    text-shadow: none;
    opacity: 0.5;
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 33px;
}

.modal-confirm .close:hover {
    opacity: 0.8;
}

.modal-confirm .icon-box {
    color: #fff;
    width: 95px;
    height: 95px;
    display: inline-block;
    border-radius: 50%;
    z-index: 9;
    border: 5px solid #fff;
    padding: 15px;
    text-align: center;
}

.modal-confirm .icon-box i {
    font-size: 58px;
    margin: -2px 0 0 -2px;
}

.modal-confirm.modal-dialog {
    margin-top: 80px;
}

.modal-confirm button {
    cursor: pointer;
}

.modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;

}



.modal-confirm .btn,
.modal-confirm .btn:active {
    color: #fff;
    border-radius: 4px;
    background: #eeb711 !important;
    text-decoration: none;
    transition: all 0.4s;
    line-height: normal;
    border-radius: 30px;
    margin-top: 10px;
    padding: 6px 20px;
    min-width: 150px;
    border: none;
}

.modal-confirm .btn:hover,
.modal-confirm .btn:focus {
    background: #eda645 !important;
    outline: none;
}

.trigger-btn {
    display: inline-block;
    margin: 100px auto;
}

/* -------------------- */

#modal {
    width: 250px;
    height: 150px;
    position: fixed;
    transform: translate(-50%, -100%);
    top: 0;
    left: 50%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    z-index: 9999999;
    transition: .3s;
    color: #ff0000;
}

#modal-static.false {
    width: 100%;
    height: 25px;
    display: flex;
    justify-content: center;
    color: #ff0000;
    display: none;
}



#modal-static.true {
    width: 100%;
    height: 25px;
    display: flex;
    justify-content: center;
    color: #ff0000;
    position: relative;
}

#modal-static.false p {
    position: absolute;
    transform: translate(0, -100%);
    transition: .2s;
}

#modal-static.true p {
    position: absolute;
    transform: translate(0, 0);
    transition: .2s;
}

#modal.true {
    top: 50%;
    transform: translate(-50%, -50%);
    transition: .3s;
}

#modal.success.true {
    width: 100%;
    height: auto;
    height: 25px;
    display: flex;
    justify-content: center;
    color: #c5393f;
    background-color: transparent;
    position: relative;
    margin: 25px 0 0 0;
    position: relative;
}


#modal.success.false {
    width: 100%;
    height: auto;
    height: 25px;
    display: flex;
    justify-content: center;
    color: #c5393f;
    background-color: transparent;
    position: relative;
    margin: 25px 0 0 0;
    position: relative;
    display: none;
}

#modal.success.false p {
    position: absolute;
    transform: translate(0, -100%);
    transition: .2s;
    color: #252422;
}

#modal.success.true p {
    position: absolute;
    transform: translate(0, 0);
    transition: .2s;
    color: #ff0000;
    width: 85%;
}

#modal>svg {
    stroke: aliceblue;
    stroke-width: 50px;
    stroke-dasharray: 2500px;
    animation: error 5s linear infinite;
    fill: #ffffff81;
}

#modal>p {
    color: #ffffffea;
    text-align: center;
}

#modal>button {
    border: none;
    width: 100%;
    height: auto;
    margin-top: 15px;
    background-color: #ffffff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 3px;
    font-size: 16px;
    color: #2b2b2b81;
    cursor: pointer;
    transition: .2s;
}

/* input:disabled {
    border: 2px #b7802167 solid;
}
div:has(.react-datepicker__input-container > #endDatePicker:disabled) > label {
    color: #b7802167;
}  */
#modal>button:hover {
    color: #ffffff;
    background-color: #ffffff75;
    transition: .2s;
}

#modal[class*="error"] {
    background: #ee0000b9;
}

#modal[class*="success"] {
    background: #02a044b9;
}

@keyframes error {
    0% {
        stroke-dashoffset: 0;
    }

    25% {
        stroke-dashoffset: 2500px;
    }

    50% {
        stroke-dashoffset: 0px;
    }

    75% {
        stroke-dashoffset: -2500px;
    }

    100% {
        stroke-dashoffset: 0px;
    }
}

@keyframes modal {
    0% {
        top: -250px;
    }

    12% {
        top: 50%
    }

    88% {
        top: 50%;
    }

    100% {
        top: -250px;
    }
}

.addSubUserPageButton {
    margin-top: 15%;
}

#subUserPage {
    display: none;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 15px;
    border-top: 1px solid #ccc;
}

.addSubUserInput {
    padding: 6px;
    border-radius: 7px;
    border: 1px solid #a87b52 !important;
    border-color: #5F471E;
    height: 30px;
    width: 238px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #5F471E;
    font-size: 16px;
    gap: 5px;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 44px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
}

.label--addSubUserInput {
    padding: 10px;
}



#subUserPageInputWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-bottom: 15px;
}

#subUserPageInputWrapper .btn {
    height: 100%;
    padding: 12px;
    margin: 40px 10px 10px;
    color: white;
    background-color: #634614;
    border: none;
}

.input--field:first-child {
    padding: 0 10px 0 10px;
}

.input--field {
    padding: 0 10px;
}

.data--box {
    display: flex;
    margin: 0px;
    flex-wrap: wrap;
    width: 100%;
}

.data--box .data-box {
    margin: 20px;
    display: block;
    min-width: 220px;
    width: 220px;
}

.data--box .data-box h2 {
    margin: 6px;
}

.data--box .data-box ul {
    list-style-type: none;
}

.data--box .data-box ul li {
    margin: 6px;
}

.data--box .data-box ul li button {
    margin: 6px;
}

.subUserNameLine {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: stretch;
    justify-content: space-between;

}

.subUserName--a a {
    margin: 0 5px;
}

.fillRed {
    color: #B54444;
    fill: #B54444;
}

.fillGrey {
    color: grey;
    fill: grey;
}

.fillGreen {
    color: #449C44;
    fill: #449C44;
}

@media screen and (max-width: 1235px) {
    .data-box div p:nth-child(1) {}
}


@media screen and (max-width: 1060px) {
    #chartWrapper {
        width: 100%;
    }
}

@media screen and (min-width: 767px) {
    .searchBar {
        width: 623px;
    }

    .custom-controls {
        width: 200px;
    }
}

@media screen and (max-width: 660px) {
    #root {}

    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    #label--databox {
        align-items: center;
        width: 100%;
    }

    header {
        width: 100%;
        height: 43px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
    }

    header .brand-logo {
        height: 100%;
        margin-top: 0;
    }

    .logout-button {
        position: initial;
        bottom: 0;
        margin-bottom: 0;
    }

    .header-boxes>svg {
        height: 25px;
        width: 25px;
    }

    header .brand-logo>img {
        height: 90%;
        width: auto;
        margin-top: 2.5%;
    }

    #page-wrapper>div {
        margin-left: 0;
        margin-top: 75px;
    }

    #page-wrapper {
        width: 100%;
    }

    #tech-page-wrapper {
        width: 100%;
        height: 100%;
        justify-content: flex-start;
        padding: 0;
    }

    #tech-page-wrapper>.label--input {
        width: 95%;
    }

    #tech-page-wrapper>#addLocation .leaflet-container {
        width: 95% !important;
    }

    #tech-header {
        padding: 0 15px;
        height: 43px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    #tech-header::before {
        border-radius: 0;
        width: 100%;
        height: 100%;
    }

    .label--selector {
        width: 100%;
        margin: 10px;
        padding: 0;
    }

    .label--selector:nth-child(4) {
        margin-bottom: 25px;

    }

    #chartWrapper {
        width: 95%
    }

    #data-range {
        width: 90%;
        padding: 5px 10px;
    }

    #data-range>div:nth-child(2) {
        display: none;
    }

    #data-range>* {
        width: 100%;
    }

    .custom--selector-wrapper {
        width: 100%;
        margin: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .label--input {
        width: 95%;
    }

    #datePickerWrapper {
        padding: 0;
        width: 95%;
    }

    #datePickerWrapper>div {
        width: 100%;
    }

    .selection-container {
        width: 100vw;
    }

    #data-container {
        width: 100vw;
    }

    .data-box {
        width: 95%;
    }


    .sendDataButton {
        place-self: center;
        padding: 10px 51px;
        width: 95%;
        margin-top: 15px;
    }

    .css-xjimrv-Switch {
        height: 30px !important;
        width: 50px !important;
    }

    .input--field {
        padding: 0 10px;
        width: 100%;
    }

    #subUserPageInputWrapper .btn {
        width: 100%;
        margin: 30px 10px;
    }

    .addSubUserPageButton {
        margin-top: 0;
    }

    .input--field:first-child {
        padding: 0 10px;
    }

    .addSubUserInput {
        width: 100%;
    }
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    overflow-x: auto;
}

th {
    background-color: #634614;
    color: white;
}

td,
th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

tr:nth-child(even) {
    background-color: #dddddd;
}

.usersContainer {
    overflow-x: auto;
    padding: 0 10px;
}

.d-none {
    display: none !important;
}

.app-version {
    bottom: 10px;
    font-size: 10px;
}